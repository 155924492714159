/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

// Components
import {
  Picto, Tag, Button, utils,
} from 'ui-library-unlocker';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';
import DataTable from '../../components/organisms/DataTable/DataTable';
import Modal from '../../components/molecules/Modal/Modal';
import HousingBenefitsForm from '../../components/organisms/forms/HousingBenefitsForm/HousingBenefitsForm';

// Services
import {
  getHousingBenefits,
  deleteHousingBenefit,
} from '../../services/housingBenefits';

// Utils
import { showModal, hideModal } from '../../utils/modal';
import { handleDocumentDownload } from '../../utils/documents';
import { canEditOrDeleteHousingBenefit } from '../../utils/validation';

// Hooks
import { useAppContext } from '../../store/context';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Constants
import {
  HOUSING_BENEFITS_TYPES,
  SEARCH_SCOPE_LIST,
} from '../../utils/constants';

import styles from './HousingBenefitsList.module.scss';

const DELETE_CONFIRM_MODAL_ID = 'housingBenefits-delete-confirm';
const ADD_HOUSING_BENEFIT_MODAL_ID = 'add-housing-benefit-modal';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.LEASES]: 'leaseUid',
  [SEARCH_SCOPE_LIST.TENANTS]: 'tenantUid',
};

function HousingBenefitsView({
  crudModal,
  lease,
}) {
  const { t } = useTranslation();
  useDocumentTitle(t('housingBenefits.browserTitle'));
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const addFormRef = useRef();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const deleteHousingBenefitMutation = useMutation({
    mutationFn: () => deleteHousingBenefit(selectedItem?.uid),
    onSuccess: () => {
      utils.toast.success(t('housingBenefits.crud.deleteSuccess'));
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'housingBenefits-list' });
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          if (err.response.data?.message === 'RENT_DISTRIBUTION_DELETION_STATUS_EXCEPTION') {
            utils.toast.error(t('housingBenefits.crud.formErrors.RENT_DISTRIBUTION_DELETION_STATUS_EXCEPTION'));
          } else {
            utils.toast.error(t('global.form.errors.generic'));
          }
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
    onSettled: () => {
      resetSelected(DELETE_CONFIRM_MODAL_ID);
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('housingBenefits.table.columns.label'),
      accessorKey: 'name',
      size: 200,
    },
    {
      header: t('housingBenefits.table.columns.lease'),
      accessorKey: 'lease',
      size: 250,
      cell: ({ row: { original } }) => (
        <div>
          <Link to={`/leases/${original.lease?.uid}`} target="_blank" className={styles.name}>
            <p className="p-2-700">
              {original.lease?.name}
              <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
            </p>
          </Link>
        </div>
      ),
    },
    {
      header: t('housingBenefits.table.columns.tenant'),
      accessorKey: 'tenant',
      size: 200,
      cell: ({ row: { original: { tenant } } }) => (
        <div>
          <Link to={`/tenants/${tenant?.uid}`} target="_blank" className={styles.name}>
            <p className="p-2-700">
              {`${tenant?.firstName} ${tenant?.lastName}`}
              <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
            </p>
          </Link>
          <span>
            {tenant?.tenantNumber || t('housingBenefits.crud.form.noTenantNumber')}
          </span>
        </div>
      ),
    },
    {
      header: t('housingBenefits.table.columns.type'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { type } } }) => (
        <div>
          <span>{t(`housingBenefits.type.${type}`)}</span>
        </div>
      ),
    },
    {
      header: t('housingBenefits.table.columns.amount'),
      accessorKey: 'amount',
      size: 100,
      cell: ({ row: { original: { amount } } }) => (
        <div>
          <span>
            {`${utils.centsToEuro(amount)} €`}
          </span>
        </div>
      ),
    },
    {
      header: t('housingBenefits.table.columns.recurrent'),
      accessorKey: 'recurrent',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { recurrent } } }) => (
        <div>
          <Tag
            label={t(`housingBenefits.recurrent.${recurrent}`)}
            size="medium"
            variant={recurrent ? 'tertiary' : 'secondary-outline'}
          />
        </div>
      ),
    },
    {
      header: t('housingBenefits.table.columns.date'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { month, year } } }) => (
        <span>
          {month && year ? `${month.toString().padStart(2, '0')}/${year}` : '--'}
        </span>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const { uid, status, recurrent } = original;
        const options = [
          {
            id: 'edit',
            label: t('global.listOptions.edit'),
            icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              if (crudModal) {
                setSelectedItem(original);
                showModal(ADD_HOUSING_BENEFIT_MODAL_ID);
              } else {
                navigate(`/housing-benefits/${uid}`);
              }
            },
          },
          canEditOrDeleteHousingBenefit(status, recurrent) ? {
            id: 'delete',
            label: t('global.listOptions.delete'),
            icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
            onClick: () => {
              setSelectedItem(original);
              showModal(DELETE_CONFIRM_MODAL_ID);
            },
          } : null,
        ];
        return options?.filter((elem) => elem !== null).length === 0 ? null : (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-housingBenefits-${uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === uid ? null : uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === uid}
              anchorId={`more-option-housingBenefits-${uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={options}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, handleDocumentDownload, navigate, crudModal, canEditOrDeleteHousingBenefit]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'type',
      type: 'checkbox',
      title: t('housingBenefits.table.columns.type'),
      options: Object.values(HOUSING_BENEFITS_TYPES).map((value) => ({
        value,
        label: t(`housingBenefits.type.${value}`),
      })),
    },
    {
      name: 'recurrent',
      type: 'checkbox',
      title: t('housingBenefits.table.columns.recurrent'),
      options: [
        {
          value: 'true',
          label: t('housingBenefits.recurrent.true'),
        },
        {
          value: 'false',
          label: t('housingBenefits.recurrent.false'),
        },
      ],
    },
  ], []);

  const resetSelected = useCallback((modalId) => {
    setSelectedItem(null);
    hideModal(modalId);
  }, []);

  const headerBtn = useMemo(() => {
    if (crudModal) {
      return (
        <Button
          variant="primary"
          size="large"
          icon="plus"
          onClick={() => {
            setSelectedItem(null);
            showModal(ADD_HOUSING_BENEFIT_MODAL_ID);
          }}
        >
          {t('housingBenefits.add')}
        </Button>
      );
    }
    return (
      <Link to="/housing-benefits/add">
        <Button
          variant="primary"
          size="large"
          icon="plus"
        >
          {t('housingBenefits.add')}
        </Button>
      </Link>
    );
  }, [t, crudModal]);

  const handleModalClose = useCallback(() => {
    resetSelected(ADD_HOUSING_BENEFIT_MODAL_ID);
    setTimeout(() => {
      addFormRef.current.resetForm();
    });
  }, [resetSelected, addFormRef]);

  const handleModalCancel = useCallback((resetForm) => {
    resetSelected(ADD_HOUSING_BENEFIT_MODAL_ID);
    setTimeout(() => resetForm());
  }, [resetSelected]);

  const onAddSuccess = useCallback(() => {
    dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'housingBenefits-list' });
  }, []);

  const leaseRentWithCharges = useMemo(() => {
    const { charges = 0, rent = 0 } = lease || {};
    return rent + charges;
  }, [lease]);

  return (
    <div
      role="presentation"
      onClick={() => setTooltipOpen(null)}
    >
      <div className={styles.heading}>
        <h1>{t('housingBenefits.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('housingBenefits.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={getHousingBenefits}
        forcedFilters={{ leaseUid: lease?.uid ? [lease.uid] : undefined }}
        queryKey="housingBenefits-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={headerBtn}
        searchHidden={crudModal}
      />
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={deleteHousingBenefitMutation.mutate}
        onCancel={() => resetSelected(DELETE_CONFIRM_MODAL_ID)}
      />
      {crudModal && (
        <Modal
          className={styles.modal}
          id={ADD_HOUSING_BENEFIT_MODAL_ID}
          title={t('housingBenefits.crud.addTitle')}
          onClose={handleModalClose}
        >
          <div className={styles.modalContent}>
            <HousingBenefitsForm
              ref={addFormRef}
              isEditing={selectedItem !== null}
              itemToEdit={selectedItem}
              onCancel={handleModalCancel}
              onAddSuccess={onAddSuccess}
              leaseUID={lease?.uid}
              leaseTenants={lease?.tenants}
              leaseRentWithCharges={leaseRentWithCharges}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

HousingBenefitsView.propTypes = {
  crudModal: PropTypes.bool,
  lease: PropTypes.shape({
    uid: PropTypes.string,
    tenants: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
};

HousingBenefitsView.defaultProps = {
  crudModal: false,
  lease: null,
};

export default HousingBenefitsView;
