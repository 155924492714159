/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-confusing-arrow */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Picto,
  Tag,
} from 'ui-library-unlocker';
import { centsToEuro } from 'ui-library-unlocker/src/utils/money';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import TenantLeavePropertyModal from '../Tenant/TenantLeavePropertyModal';
import DataTable from '../../components/organisms/DataTable/DataTable';

// Services
import { getLeases, getConnectedTenantLeases } from '../../services/lease';

// Hooks
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';
import useFilters from '../../hooks/useFilters';

// Utils
import { showModal } from '../../utils/modal';
import { handleDocumentDownload } from '../../utils/documents';
import { formatAddress } from '../../utils/properties';
import { getLeaseStatusVariant } from '../../utils/variants';
import { getDateValue } from '../../utils/dates';

// Constants
import { SEARCH_SCOPE_LIST, LEASE_STATUS } from '../../utils/constants';

import styles from './LeaseList.module.scss';

const LEAVE_PROPERTY_MODAL_ID = 'TENANT_LEAVE_PROPERTY_MODAL_ID';
const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.LEASES]: 'uid',
  [SEARCH_SCOPE_LIST.TENANTS]: 'tenants',
  [SEARCH_SCOPE_LIST.PROPERTY_OWNERS]: 'lessorUid',
  [SEARCH_SCOPE_LIST.MANAGERS]: 'managerUid',
  [SEARCH_SCOPE_LIST.PROPERTIES]: 'propertyUid',
};

function LeaseList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUserLessor, isUserTenant } = useRoles();
  const { dispatch, context: { uiBuilders } } = useAppContext();
  const { filters } = useFilters();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [leaseLeaving, setLeaseLeaving] = useState(null);

  useEffect(() => {
    document.title = t('lease.browserTitle');
  }, [t]);

  const getLeaseUiBuilderOptions = useCallback((typeKey) => {
    if (!uiBuilders) return [];
    return Object.entries(uiBuilders?.['/lease/ui']?.[typeKey]).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  }, [uiBuilders]);

  const leaseTypes = useMemo(() => getLeaseUiBuilderOptions('leaseTypes'), [getLeaseUiBuilderOptions]);

  const leaseUsages = useMemo(() => getLeaseUiBuilderOptions('leaseUsages'), [getLeaseUiBuilderOptions]);

  const columns = useMemo(() => ([
    {
      header: t('lease.table.columns.startDate'),
      accessorKey: 'startDate',
      size: 100,
      cell: ({ row: { original: { startDate } } }) => {
        const start = getDateValue(startDate);
        return (
          <div>
            <span>{start ? format(start, 'dd MMM yyyy', { locale: fr }) : '-'}</span>
          </div>
        );
      },
    },
    {
      header: t('lease.table.columns.property'),
      accessorKey: 'propertyName',
      size: 200,
      cell: ({ row: { original: { propertyUid, propertyName, propertyAddress } } }) => (
        <div>
          <div className={styles.nameAddress}>
            {isUserLessor ? (
              <Link to={`/property/${propertyUid}`} target="_blank" className={styles.name}>
                <p className="p-2-700">
                  {propertyName}
                  <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
                </p>
              </Link>
            ) : (
              <p className="p-2-700">
                {propertyName}
              </p>
            )}
            <p className="p-2-700">
              {formatAddress(propertyAddress)}
            </p>
          </div>
        </div>
      ),
    },
    {
      header: t('lease.table.columns.tenant'),
      accessorKey: 'tenantDetails',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { tenantDetails } } }) => (
        <div>
          {Array.isArray(tenantDetails) && tenantDetails.map(({ firstName, lastName, uid }) => isUserLessor ? (
            <Link key={uid} to={`/tenant/${uid}`} target="_blank" className={styles.name}>
              <p className="p-2-700">
                {`${firstName} ${lastName}`}
                <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
              </p>
            </Link>
          ) : (
            <p key={uid} className="p-2-700">
              {`${firstName} ${lastName}`}
            </p>
          ))}
        </div>
      ),
    },
    {
      header: t('lease.table.columns.rent'),
      accessorKey: 'rent',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { rent } } }) => (
        <div>
          <span>{rent != null ? `${centsToEuro(rent)} €` : '-'}</span>
        </div>
      ),
    },
    {
      header: t('lease.table.columns.type'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { type } } }) => (
        <div>
          <span>{leaseTypes?.find((item) => item.value === type)?.label}</span>
        </div>
      ),
    },
    {
      header: t('lease.table.columns.usage'),
      accessorKey: 'usage',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { usage } } }) => (
        <div>
          <span>{leaseUsages?.find((item) => item.value === usage)?.label}</span>
        </div>
      ),
    },
    {
      header: t('lease.table.columns.nextRevisionDate'),
      accessorKey: 'nextRevisionDate',
      size: 100,
      cell: ({ row: { original: { nextRevisionDate } } }) => (
        <div>
          <span>{nextRevisionDate ? format(new Date(nextRevisionDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('lease.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          <Tag
            label={t(`lease.table.status.${status}`)}
            size="medium"
            variant={getLeaseStatusVariant(status)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-lease-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-lease-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                !isUserTenant ? (
                  {
                    id: 'manage',
                    label: t('global.listOptions.manage'),
                    icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      navigate(`/lease/${row?.original?.uid}`);
                    },
                  }
                ) : (
                  {
                    id: 'see',
                    label: t('global.listOptions.see'),
                    icon: <Picto icon="eye" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="eye" width={24} color="var(--color-white)" />,
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      navigate(`/lease/${row?.original?.uid}`);
                    },
                  }
                ),
                row?.original?.documentUID ? (
                  {
                    id: 'download',
                    label: t('global.listOptions.download'),
                    icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      handleDocumentDownload({ uid: row?.original?.documentUID }, t);
                    },
                  }
                ) : null,
                row?.original?.status && row?.original?.status !== LEASE_STATUS.TERMINATED && isUserLessor ? (
                  {
                    id: 'terminateLease',
                    label: t('global.listOptions.terminateLease'),
                    icon: <Picto icon="logout" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="logout" width={24} color="var(--color-white)" />,
                    onClick: () => {
                      setLeaseLeaving(row?.original);
                      showModal(LEAVE_PROPERTY_MODAL_ID);
                    },
                  }
                ) : null,
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, isUserLessor, isUserTenant, navigate]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('lease.table.columns.status'),
      options: Object.values(LEASE_STATUS).map((status) => ({
        value: status,
        label: t(`lease.table.status.${status}`),
      })),
    },
    {
      name: 'type',
      type: 'checkbox',
      title: t('lease.table.columns.type'),
      options: leaseTypes,
    },
    {
      name: 'usage',
      type: 'checkbox',
      title: t('lease.table.columns.usage'),
      options: leaseUsages,
    },
    {
      name: 'startDate',
      multiName: ['startDateDate', 'startDateStartDate', 'startDateEndDate'],
      type: 'date',
      title: t('lease.table.columns.startDate'),
      options: [
        {
          value: filters?.startDateDate?.[0],
          label: `${t('global.filterDate.exactSelectedFilterLabel')} ${filters?.startDateDate?.[0]
            ? format(new Date(filters?.startDateDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.startDateStartDate?.[0],
          label: `${t('global.filterDate.after')} ${filters?.startDateStartDate?.[0]
            ? format(new Date(filters?.startDateStartDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.startDateEndDate?.[0],
          label: `${t('global.filterDate.before')} ${filters?.startDateEndDate?.[0]
            ? format(new Date(filters?.startDateEndDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
      ],
    },
  ], [leaseTypes, leaseUsages, filters]);

  const leaseListRefetch = useCallback(() => {
    dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'lease-list' });
  }, [dispatch]);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <div className={styles.heading}>
        <h1>{t('lease.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('lease.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={isUserTenant ? getConnectedTenantLeases : getLeases}
        queryKey="lease-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={isUserLessor && (
          <Link to="/lease/add">
            <Button
              variant="primary"
              size="large"
              icon="plus"
            >
              {t('lease.addBtn')}
            </Button>
          </Link>
        )}
      />
      <TenantLeavePropertyModal
        id={LEAVE_PROPERTY_MODAL_ID}
        lease={leaseLeaving}
        refetch={leaseListRefetch}
      />
    </div>
  );
}

export default LeaseList;
