import React, {
  useMemo, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import TagManager from 'react-gtm-module';
import {
  utils,
  TextInput,
  DateInput,
  SelectInput,
  PhoneInput,
  Button,
  Message,
  UnlockerLoader,
  RadioGroup,
} from 'ui-library-unlocker';

// Components
import AddressInput from '../../../molecules/AddressInput/AddressInput';
import ValidationChangeModal from '../../../molecules/ValidationChangeModal/ValidationChangeModal';
import CityInput from '../../../molecules/CityInput/CityInput';
import IbanInput from '../../../molecules/IbanInput/IbanInput';

// Services
import { checkExistingEmail, createInvitation, impersonate } from '../../../../services/identity';

// Utils
import {
  personalInfoInitialValues,
  personalInfoSchema,
  ownerInvitePersonalInfoSchema,
} from '../../../../utils/forms/personalInfoSchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';
import { checkIfSendProfileValidationRequest } from '../../../../utils/user';
import { showModal } from '../../../../utils/modal';

// Hooks
import useScroll from '../../../../hooks/useScroll';
import useRoles from '../../../../hooks/useRoles';
import { useAppContext } from '../../../../store/context';

// Constants
import { ENROLMENT_STATUS, RENT_DISTRIBUTION_STATUS } from '../../../../utils/constants';
import { DEPARTMENTS, NATIONALITIES } from '../../../../utils/countries';

import styles from './PersonalInfoForm.module.scss';

const PROFILE_CHANGE_MODAL_ID = 'profile-change-modal';

function PersonalInfoForm({
  isProfilePristine, userQuery, error, isReadOnly, userMutation, statusMessage,
}) {
  const { context: { me } } = useAppContext();
  const { t } = useTranslation();
  const { scrollContentToTop } = useScroll();
  const navigate = useNavigate();
  const { isUserAdmin, isUserTenant } = useRoles();
  const location = useLocation();
  const ibanRef = useRef(null);

  const {
    data: userProfileData,
    isLoading,
  } = userQuery;

  const IS_FROM_PROFILE = location.pathname.startsWith('/profile');
  const IS_ADDING_OR_PROFILE_TO_COMPLETE = userProfileData == null;

  const twentyYearsBackDate = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 20);
    return date;
  }, []);

  const initialValues = useMemo(() => {
    if (error) {
      return personalInfoInitialValues;
    }
    if (userProfileData) {
      const userBirthDate = userProfileData?.data?.birthDate;
      return {
        ...userProfileData?.data,
        birthDate: userBirthDate ? new Date(userBirthDate) : twentyYearsBackDate,
        taxCountry: 'FR',
        address: userProfileData?.data?.address || null,
      };
    }
    return personalInfoInitialValues;
  }, [userProfileData, error]);

  const genderOptions = useMemo(() => [{
    value: 'male',
    label: t('profile.personalInfo.form.gender.male'),
  }, {
    value: 'female',
    label: t('profile.personalInfo.form.gender.female'),
  }, {
    value: 'none',
    label: t('profile.personalInfo.form.gender.other'),
  }], [t]);

  const titleOptions = useMemo(() => [{
    value: 'mr',
    label: t('profile.personalInfo.form.title.mr'),
  }, {
    value: 'ms',
    label: t('profile.personalInfo.form.title.ms'),
  }, {
    value: 'undefined',
    label: t('profile.personalInfo.form.title.undefined'),
  }], [t]);

  const nationalityOptions = useMemo(() => Object.keys(NATIONALITIES).map((countryCode) => ({
    value: countryCode,
    label: NATIONALITIES[countryCode],
  })), [t]);

  const birthDepartmentOptions = useMemo(() => DEPARTMENTS.map((dpt) => ({
    value: dpt.code,
    label: dpt.nom,
  })), [t]);

  const formikValidationSchema = useMemo(() => {
    if (IS_FROM_PROFILE) return personalInfoSchema;
    return ownerInvitePersonalInfoSchema;
  }, [
    personalInfoSchema,
    ownerInvitePersonalInfoSchema,
    IS_FROM_PROFILE,
  ]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        formikValidationSchema.validateSync(values, {
          context: {
            isUserTenant,
          },
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, er) => {
          errors[er.path] = er.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (
        ![
          ENROLMENT_STATUS.PENDING,
          ENROLMENT_STATUS.PROFILE_DONE,
        ].includes(userProfileData?.data?.onboardingStatus)
        && !IS_ADDING_OR_PROFILE_TO_COMPLETE
        && !isUserTenant
      ) {
        showModal(PROFILE_CHANGE_MODAL_ID);
        formik.setSubmitting(false);
        return;
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'profile_set',
        },
      });
      userMutation.mutate(values);
      ibanRef.current?.resetEditMode();
    },
  });

  const checkEmailMutation = useMutation({
    mutationFn: checkExistingEmail,
    keepPreviousData: true,
  });

  const sendInviteMutation = useMutation({
    mutationFn: (guestEmail) => createInvitation({ guestEmail, relation: 'propertyOwner' }),
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('owners.sendInvite.success'));
          if (location.pathname.startsWith('/owner')) navigate('/owner');
          if (location.pathname.startsWith('/tenant')) navigate('/tenant');
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        utils.toast.error(t('owners.sendInvite.error'));
      }
    },
  });

  const {
    mutate: postImpersonateMutate,
    isLoading: postImpersonateLoading,
  } = useMutation({
    mutationKey: ['impersonate'],
    mutationFn: impersonate,
  });

  useEffect(() => {
    if (userMutation.isSuccess) {
      scrollContentToTop();
      if (!isUserAdmin) {
        userMutation.reset();
        navigate('#documents');
      }
      formik.setSubmitting(false);
    }
  }, [userMutation.isSuccess]);

  useEffect(() => {
    if (userMutation.isError) {
      formik.setErrors(userMutation.error?.response?.data?.errors);
      formik.setSubmitting(false);
    }
  }, [userMutation.isError]);

  const birthDateValue = useMemo(() => {
    try {
      if (!formik.values.birthDate) return null;
      return new Date(formik.values.birthDate);
    } catch {
      return null;
    }
  }, [formik.values.birthDate]);

  const renderPhoneInputs = () => (
    <>
      <PhoneInput
        id="mobilePhoneNumber"
        name="mobilePhoneNumber"
        label={t('profile.personalInfo.form.mobilePhone')}
        className="m-t-25"
        error={displayError(t, formik, 'mobilePhoneNumber')}
        valid={isFieldValid(formik, 'mobilePhoneNumber', null, initialValues?.mobilePhoneNumber)}
        value={{
          countryCode: formik?.values?.mobilePhoneCountryCode,
          phone: formik?.values?.mobilePhoneNumber,
        }}
        disabled={isReadOnly}
        onBlur={formik.handleBlur}
        onChange={(value) => {
          const { countryCode, phone } = value;
          formik.setFieldValue('mobilePhoneCountryCode', countryCode);
          formik.setFieldValue('mobilePhoneNumber', phone);
        }}
      />
      {(IS_FROM_PROFILE || isReadOnly) && (
        <PhoneInput
          id="phoneNumber"
          name="phoneNumber"
          label={`${t('profile.personalInfo.form.phone')} ${t('global.form.optional')}`}
          className="m-t-25"
          error={displayError(t, formik, 'phoneNumber')}
          valid={isFieldValid(formik, 'phoneNumber', null, initialValues?.phoneNumber)}
          value={{
            countryCode: formik?.values?.phoneCountryCode,
            phone: formik?.values?.phoneNumber,
          }}
          disabled={isReadOnly}
          onBlur={formik.handleBlur}
          onChange={(value) => {
            const { countryCode, phone } = value;
            formik.setFieldValue('phoneCountryCode', countryCode);
            formik.setFieldValue('phoneNumber', phone);
          }}
        />
      )}
    </>
  );

  useEffect(() => {
    if (isReadOnly || IS_FROM_PROFILE) return () => {};
    const delayDebounceFn = setTimeout(async () => {
      if (formik.values.email?.length < 4) return;
      try {
        checkEmailMutation.mutate({ email: formik.values.email });
        formik.setFieldTouched('email', true);
      } catch (err) {
        if (err?.response) {
          //
        }
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [formik.values.email]);

  const isEmailExisting = formik.values?.email ? !!checkEmailMutation?.data : false;

  const SHOULD_RENDER_FORM = (
    !IS_ADDING_OR_PROFILE_TO_COMPLETE
    || (IS_FROM_PROFILE || (!isEmailExisting && checkEmailMutation.isSuccess))
  );

  const handleBirthCitySelect = useCallback((value) => {
    formik.setFieldValue('birthCity', value?.city);
    formik.setFieldValue('birthCountry', value?.countryCode);
    // As of now, set the birthDepartment to 991 (Europe) if the birthCountry is not France
    if (value?.countryCode !== 'FR') {
      return formik.setFieldValue('birthDepartment', '991');
    }
    if (value?.zipCode) {
      return formik.setFieldValue('birthDepartment', value.zipCode.slice(0, 2));
    }
    return formik.setFieldValue('birthDepartment', '');
  }, [formik]);

  const mustSendValidationRequest = checkIfSendProfileValidationRequest(formik.values.rentDistribution);

  const shoudlDisplayIbanInput = useMemo(() => {
    if (isUserTenant) return false;
    if (!IS_FROM_PROFILE && !isReadOnly) return false;
    return mustSendValidationRequest;
  }, [isUserTenant, userProfileData?.data?.rentDistribution, isReadOnly, mustSendValidationRequest]);

  const shoudlDisplayImpersonateBtn = useMemo(() => (
    isUserAdmin && !IS_FROM_PROFILE && !IS_ADDING_OR_PROFILE_TO_COMPLETE
  ), [isUserAdmin]);

  const rentDistributionOptions = useMemo(() => Object.values(RENT_DISTRIBUTION_STATUS).map((status) => ({
    id: status,
    value: status,
    label: t(`profile.personalInfo.rentDistribution.rentDistributionOptions.${status}`),
  })), [t]);

  const handleRentDistribution = useCallback((value) => {
    formik.setFieldValue('rentDistribution', value);
  }, [formik]);

  if (isLoading) return <UnlockerLoader size={200} align="left" />;

  return (
    <>
      {(isProfilePristine && !isUserTenant) && (
        <Message className="m-t-30" content={t('profile.pristine')} />
      )}
      {shoudlDisplayImpersonateBtn && (
        <Button
          className="m-t-20"
          onClick={() => postImpersonateMutate(userProfileData?.data)}
          loading={postImpersonateLoading}
        >
          {t('global.listOptions.impersonate')}
        </Button>
      )}
      <div className={styles.wrapper}>
        <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
          <h2 className="m-b-30">{t('profile.personalInfo.title')}</h2>
          <div className={styles.inputGroup}>
            {userProfileData?.data?.unlockerId && (
              <TextInput
                type="text"
                id="unlockerId"
                name="unlockerId"
                className="m-b-25"
                label={t('profile.personalInfo.form.unlockerId')}
                value={userProfileData?.data?.unlockerId}
                disabled
              />
            )}
            {!IS_FROM_PROFILE && (
              <TextInput
                type="email"
                id="email"
                name="email"
                disabled={isReadOnly}
                className="m-t-25"
                label={t('owner.crud.form.email')}
                error={checkEmailMutation.isSuccess && displayError(t, formik, 'email')}
                valid={isEmailExisting ? null : isFieldValid(formik, 'email', null, initialValues.email)}
                info={(
                  !isReadOnly
                && !isEmailExisting
                && isFieldValid(formik, 'email', null, initialValues.email)
                ) ? t('global.emailAvailable') : null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                loading={checkEmailMutation.isLoading}
              />
            )}
          </div>
          {SHOULD_RENDER_FORM && (
          <>
            <div className={styles.inputGroup}>
              <TextInput
                type="text"
                id="firstName"
                name="firstName"
                className="m-t-25"
                label={t('profile.personalInfo.form.firstName')}
                error={displayError(t, formik, 'firstName')}
                valid={isFieldValid(formik, 'firstName', null, initialValues?.firstName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                disabled={isReadOnly}
              />
              {(IS_FROM_PROFILE || isReadOnly) && !isUserTenant && (
                <TextInput
                  type="text"
                  id="secondName"
                  name="secondName"
                  className="m-t-25"
                  label={`${t('profile.personalInfo.form.secondName')} ${t('global.form.optional')}`}
                  error={displayError(t, formik, 'secondName')}
                  valid={isFieldValid(formik, 'secondName', null, initialValues?.secondName)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondName}
                  disabled={isReadOnly}
                />
              )}
              <TextInput
                type="text"
                id="lastName"
                name="lastName"
                className="m-t-25"
                label={t('profile.personalInfo.form.lastName')}
                error={displayError(t, formik, 'lastName')}
                valid={isFieldValid(formik, 'lastName', null, initialValues?.lastName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                disabled={isReadOnly}
              />
              {(IS_FROM_PROFILE || isReadOnly) && !isUserTenant && (
                <TextInput
                  type="text"
                  id="birthName"
                  name="birthName"
                  className="m-t-25"
                  label={`${t('profile.personalInfo.form.birthName')} ${t('global.form.optional')}`}
                  error={displayError(t, formik, 'birthName')}
                  valid={isFieldValid(formik, 'birthName', null, initialValues?.birthName)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birthName}
                  disabled={isReadOnly}
                />
              )}

              {renderPhoneInputs()}

              {isUserTenant && (
              <>
                <DateInput
                  id="birthDate"
                  name="birthDate"
                  className={utils.cn(['m-t-25', styles.dateInput])}
                  label={t('profile.personalInfo.form.birthDate')}
                  error={displayError(t, formik, 'birthDate')}
                  valid={isFieldValid(formik, 'birthDate', null, initialValues?.birthDate)}
                  onChange={(date) => {
                    formik.setFieldValue('birthDate', date);
                  }}
                  onBlur={formik.handleBlur}
                  value={birthDateValue}
                  disabled={isReadOnly}
                />
                <TextInput
                  type="text"
                  id="tenantNumber"
                  name="tenantNumber"
                  className="m-t-25"
                  label={`${t('tenant.crud.form.tenantNumber')} ${t('global.form.optional')}`}
                  error={displayError(t, formik, 'tenantNumber')}
                  valid={isFieldValid(formik, 'tenantNumber')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tenantNumber}
                  disabled={isReadOnly}
                />
              </>
              )}

              {(IS_FROM_PROFILE || isReadOnly) && !isUserTenant && (
              <>
                <h2 className="m-t-40">{t('profile.personalInfo.civilState')}</h2>
                <SelectInput
                  id="gender"
                  name="gender"
                  className="m-t-25"
                  label={t('profile.personalInfo.form.gender.label')}
                  options={genderOptions}
                  error={displayError(t, formik, 'gender')}
                  valid={isFieldValid(formik, 'gender', null, initialValues?.gender)}
                  onChange={(value) => {
                    formik.setFieldValue('gender', value ? value.value : null);
                    if (value?.value === 'male') formik.setFieldValue('title', 'mr');
                    if (value?.value === 'female') formik.setFieldValue('title', 'ms');
                  }}
                  onBlur={formik.handleBlur}
                  value={genderOptions.find((gender) => gender.value === formik.values.gender)}
                  disabled={isReadOnly}
                />
                <SelectInput
                  id="title"
                  name="title"
                  className="m-t-25"
                  label={t('profile.personalInfo.form.title.label')}
                  options={titleOptions}
                  error={displayError(t, formik, 'title')}
                  valid={isFieldValid(formik, 'title', null, initialValues?.title)}
                  onChange={(value) => {
                    formik.setFieldValue('title', value ? value.value : null);
                  }}
                  onBlur={formik.handleBlur}
                  value={titleOptions.find((title) => title.value === formik.values.title)}
                  disabled={isReadOnly}
                />
                <DateInput
                  id="birthDate"
                  name="birthDate"
                  className={utils.cn(['m-t-25', styles.dateInput])}
                  label={t('profile.personalInfo.form.birthDate')}
                  error={displayError(t, formik, 'birthDate')}
                  valid={isFieldValid(formik, 'birthDate', null, initialValues?.birthDate)}
                  onChange={(date) => {
                    formik.setFieldValue('birthDate', date);
                  }}
                  onBlur={formik.handleBlur}
                  value={birthDateValue}
                  disabled={isReadOnly}
                />
                <CityInput
                  id="birthCity"
                  name="birthCity"
                  className="m-t-25"
                  label={t('profile.personalInfo.form.birthCity')}
                  error={displayError(t, formik, 'birthCity', 'text', { errorIfUntouched: true })}
                  valid={isFieldValid(formik, 'birthCity', null, initialValues?.birthCity)}
                  onAddressSelect={handleBirthCitySelect}
                  value={formik.values.birthCity}
                  disabled={isReadOnly}
                />
                { formik.values.birthCountry === 'FR' && (
                  <SelectInput
                    id="birthDepartment"
                    name="birthDepartment"
                    className={utils.cn(['m-t-10', styles.largeInput])}
                    label={t('profile.personalInfo.form.birthDepartment')}
                    options={birthDepartmentOptions}
                    error={displayError(t, formik, 'birthDepartment')}
                    valid={isFieldValid(formik, 'birthDepartment', null, initialValues?.birthDepartment)}
                    onChange={(value) => {
                      formik.setFieldValue('birthDepartment', value.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={birthDepartmentOptions.find((option) => option.value === formik.values.birthDepartment)}
                    disabled={isReadOnly}
                  />
                )}
                <SelectInput
                  id="nationality"
                  name="nationality"
                  className={utils.cn(['m-t-25', styles.largeInput])}
                  label={t('profile.personalInfo.form.nationality')}
                  options={nationalityOptions}
                  error={displayError(t, formik, 'nationality')}
                  valid={isFieldValid(formik, 'nationality', null, initialValues?.nationality)}
                  onChange={(value) => {
                    formik.setFieldValue('nationality', value?.value || null);
                  }}
                  onBlur={formik.handleBlur}
                  value={nationalityOptions.find((nationality) => nationality.value === formik.values.nationality)}
                  disabled={isReadOnly}
                />
                <AddressInput
                  id="address"
                  name="address"
                  className={utils.cn([styles.largeInput, 'm-t-25'])}
                  label={t('profile.personalInfo.form.address')}
                  error={formik?.submitCount > 0 ? t(formik?.errors?.address) : null}
                  valid={isFieldValid(formik, 'address', null, initialValues?.address)}
                  onAddressSelect={(value) => {
                    formik.setFieldValue('address', value);
                  }}
                  value={formik.values?.address || null}
                  disabled={isReadOnly}
                />
              </>
              )}
            </div>

            {!isUserTenant && (
            <>
              <h2 className="m-t-40 m-b-20">
                {t('profile.personalInfo.rentDistribution.title')}
              </h2>
              <Message
                content={<Trans i18nKey="profile.personalInfo.rentDistribution.rentDistributionMeaning" />}
                variant="info"
                className={utils.cn([styles.requiredDocumentsPhysical])}
              />
              <RadioGroup
                name="rentDistribution"
                className="m-t-25"
                options={rentDistributionOptions}
                value={formik.values.rentDistribution}
                onChange={handleRentDistribution}
                disabled={isReadOnly}
              />
              <p className="p-2-500 m-t-25 m-b-10">
                <Trans i18nKey="profile.personalInfo.rentDistribution.rentDistributionInfo">
                  <a href="mailto:support@unlocker.io">support@unlocker.io</a>
                </Trans>
              </p>
            </>
            )}

            {shoudlDisplayIbanInput && (
            <>
              <h2 className="m-t-50 m-b-30">
                {t('profile.personalInfo.bankingInfo')}
              </h2>
              <div className={styles.inputGroup}>
                <IbanInput
                  id="iban"
                  name="iban"
                  className="m-t-25"
                  label={t('profile.personalInfo.form.iban')}
                  error={displayError(t, formik, 'iban')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.iban}
                  disabled={isReadOnly}
                  editMode={!!initialValues.iban}
                  ref={ibanRef}
                />
              </div>
            </>
            )}
            <TextInput
              type="hidden"
              id="taxCountry"
              name="taxCountry"
              label={t('profile.companyDetails.form.taxCountry')}
              value={formik.values?.taxCountry}
            />

            {!isReadOnly && (
              <div className={styles.submit}>
                <Button
                  type="submit"
                  size="large"
                  loading={formik.isSubmitting}
                  label={t('profile.loginInfo.form.submit')}
                />
                <button
                  type="button"
                  className={styles.cancel}
                  onClick={() => {
                    formik.resetForm();
                    if (initialValues.iban) ibanRef.current?.resetEditMode();
                    utils.toast.info(t('profile.personalInfo.form.cancelMessage'));
                  }}
                >
                  <a href="#personal-info">
                    {t('profile.loginInfo.form.cancel')}
                  </a>
                </button>
              </div>
            )}
          </>
          )}
          {IS_ADDING_OR_PROFILE_TO_COMPLETE && isEmailExisting && (
            <div>
              <p className="m-t-25 p-2-500">
                {formik.values.email !== me.email ? (
                  t('owners.form.existingOwnerInfo')
                ) : t('owners.form.sameUser')}
              </p>
              {formik.values.email !== me.email && (
                <Button
                  type="button"
                  variant="primary"
                  className="m-t-10"
                  loading={sendInviteMutation.isLoading}
                  onClick={() => sendInviteMutation.mutate(formik.values.email)}
                >
                  {t('global.sendInvite')}
                </Button>
              )}
            </div>
          )}
        </form>
        <div>
          {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
        </div>
        <ValidationChangeModal
          id={PROFILE_CHANGE_MODAL_ID}
          onAccept={() => userMutation.mutate(formik.values)}
          submitLoading={userMutation.isLoading}
          entity="profile"
        />
      </div>
    </>
  );
}

PersonalInfoForm.propTypes = {
  isProfilePristine: PropTypes.bool.isRequired,
  userQuery: PropTypes.shape().isRequired,
  userMutation: PropTypes.shape({
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isError: PropTypes.bool,
    reset: PropTypes.func,
    error: PropTypes.shape(),
  }).isRequired,
  error: PropTypes.shape(),
  isReadOnly: PropTypes.bool,
  statusMessage: PropTypes.shape({ displayed: PropTypes.bool, value: PropTypes.string }),
};

PersonalInfoForm.defaultProps = {
  error: null,
  isReadOnly: false,
  statusMessage: { display: false, value: '' },
};

export default PersonalInfoForm;
