import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link, useLocation, useMatch, useNavigate,
} from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Picto, Tag, utils } from 'ui-library-unlocker';

// Components
import OwnerDocuments from './OwnerDocuments/OwnerDocuments';

// Services
import { createOwner, getOwner, updateOwner } from '../../services/owner';
import { getOwnerFromAdmin } from '../../services/admin';

// Hooks
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';

import styles from './OwnerEdit.module.scss';
import PersonalInfoForm from '../../components/organisms/forms/PersonalInfoForm/PersonalInfoForm';

function OwnerEdit({ isEditing }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { isUserAdmin } = useRoles();
  const { refetchMe, context: { me } } = useAppContext();
  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });
  const navigate = useNavigate();

  const match = useMatch('/owner/:id');

  useEffect(() => {
    switch (location.hash) {
      case '#documents':
        document.title = t('owner.tabs.documents.browserTitle');
        break;
      default:
        document.title = t('owner.tabs.generalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const ownerQuery = useQuery({
    queryKey: ['property-id', isEditing, match?.params?.id],
    queryFn: () => {
      if (isEditing && match?.params?.id !== 'add') {
        return (isUserAdmin ? getOwnerFromAdmin : getOwner)(match?.params?.id);
      }
      return null;
    },
    cacheTime: 0,
  });

  const ownerMutation = useMutation({
    mutationFn: isEditing ? (data) => updateOwner(match?.params?.id, data) : createOwner,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          refetchMe();
          utils.toast.success(t('owner.tabs.generalInfo.formSuccess'));
          setStatusMessage({ displayed: true, value: t('owner.tabs.generalInfo.formSuccess') });
          navigate('/owner');
          break;
        case 204:
          refetchMe();
          ownerQuery.refetch().then(() => {
            setStatusMessage({ displayed: true, value: t('owner.tabs.generalInfo.formSuccess') });
            utils.toast.success(t('owner.crud.form.formInfos.editSuccess'));
          });
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          case 409: {
            switch (err.response.data?.message) {
              case 'ERR_IDENTITY_USERNAME_CONFLICT': {
                utils.toast.alert(t('owner.crud.form.errorResponses.ERR_IDENTITY_USERNAME_CONFLICT'));
                break;
              }
              default: {
                utils.toast.alert(t('globals.form.submit.generic'));
                break;
              }
            }
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const isUnderUserDelegation = useMemo(() => (
    me?.aclMatrix?.userDelegations?.some((delegUser) => delegUser.user === match?.params?.id)
  ), [me, match?.params?.id]);

  const isEditable = useMemo(() => {
    if (isUserAdmin) return true;
    if (!isEditing) return false;
    if (isUnderUserDelegation) return true;
    return false;
  }, [isEditing, isUserAdmin, isUnderUserDelegation]);

  const tabs = useMemo(() => [
    {
      label: isEditing ? t('owner.tabs.generalInfo.tabTitleEdit') : t('owner.tabs.generalInfo.tabTitleAdd'),
      title: t('owner.tabs.generalInfo.title'),
      hash: '',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <PersonalInfoForm
          isReadOnly={!isEditable && isEditing}
          isProfilePristine={false}
          userQuery={ownerQuery}
          userMutation={ownerMutation}
          statusMessage={statusMessage}
        />
      ),
      displayed: true,
    },
    {
      label: t('owner.tabs.documents.tabTitle'),
      title: t('owner.tabs.documents.title'),
      hash: 'documents',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <OwnerDocuments
          rentDistribution={ownerQuery?.data?.data?.rentDistribution}
        />
      ),
      displayed: true,
    },
  ], [t, location, isEditing, ownerQuery]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    const tab = tabs.find((cur) => cur.hash === hash);
    return tab || tabs[0];
  }, [tabs, location]);

  const {
    firstName, lastName, legalName, status,
  } = ownerQuery?.data?.data || {};

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1>{isEditing ? t('owner.crud.editTitle') : t('owner.crud.addTitle')}</h1>
          { isEditing && ownerQuery?.data && (
          <div className={utils.cn(['m-t-20', styles.editDetails])}>
            <h2 className="t-h2-500">{`${firstName} ${lastName} ${legalName ? `- ${legalName}` : ''}`}</h2>
            <Tag
              label={t(`global.personStatus.${status}`)}
              size="medium"
              variant={status === 'enabled' ? 'secondary' : 'error'}
            />
          </div>
          )}
        </div>
        {isEditing ? (
          <ul className={styles.tabList}>
            {tabs.filter((tab) => tab.displayed).map((tab) => {
              const isActive = tab.hash === currentTab.hash;

              return (
                <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash}>
                  <Link to={`#${tab.hash}`}>{tab.label}</Link>
                  {isActive && tab.activePicto}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

OwnerEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default OwnerEdit;
