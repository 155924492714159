/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Components
import {
  utils,
  Picto,
  Tag,
} from 'ui-library-unlocker';
import EmailModal from '../../../../components/molecules/EmailModal/EmailModal';

// Services
import {
  getPendingLeases,
  getExpiringLeases,
  getEmailsSent,
} from '../../../../services/stats';

// Utils
import { showModal, hideModal } from '../../../../utils/modal';

// Hooks
import useResponsive from '../../../../hooks/useResponsive';

// Styles
import styles from './DashboardKPILists.module.scss';

const EMAIL_MODAL_ID = 'emailModal';

function List({
  title,
  data,
  isLoading,
  className,
  id,
  getTagVariant,
}) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const listData = useMemo(() => data || [], [data]);

  if (isLoading) {
    return (
      <div
        id={id}
        className={utils.cn([
          styles.listContainer,
          className,
        ])}
      >
        <Skeleton className="m-b-20" width="30%" />
        <Skeleton className={styles.listItem} />
        <Skeleton className={styles.listItem} />
        <Skeleton className={styles.listItem} />
      </div>
    );
  }

  return (
    <div
      id={id}
      className={utils.cn([
        styles.listContainer,
        className,
      ])}
    >
      <h4 className="m-b-20">{title}</h4>
      <div className={styles.list}>
        {listData.length === 0
          ? (
            <p className={styles.noResults}>
              {t('global.noResults')}
            </p>
          )
          : listData.map((item) => {
            const tagVariant = getTagVariant
              ? getTagVariant(item)
              : {
                variant: 'primary',
                label: 'global.form.unknown',
                icon: 'alert-triangle',
              };
            return (
              <div key={item.uid} className={styles.listItem}>
                {item.onClick ? (
                  <p
                    className={styles.link}
                    onClick={item.onClick}
                    role="button"
                    onKeyDown={item.onClick}
                  >
                    <span>
                      {item.name}
                    </span>
                    <Picto
                      icon="export-link"
                      color="var(--color-secondary)"
                      width={10}
                    />
                  </p>
                ) : (
                  <Link
                    className={styles.link}
                    to={item.redirectUrl}
                    target="_blank"
                  >
                    <span>
                      {item.name}
                    </span>
                    <Picto
                      icon="export-link"
                      color="var(--color-secondary)"
                      width={10}
                    />
                  </Link>
                )}
                <Tag
                  variant={tagVariant.variant}
                  size={isMobile ? 'small' : 'medium'}
                  label={tagVariant.label}
                  icon={tagVariant.icon}
                  className={styles.tag}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

List.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    uid: PropTypes.string,
    isNextSignerLessor: PropTypes.bool,
    terminationReason: PropTypes.string,
    redirectUrl: PropTypes.string,
    status: PropTypes.string,
    onClick: PropTypes.func,
  })),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  getTagVariant: PropTypes.func,
};

List.defaultProps = {
  isLoading: false,
  className: '',
  id: '',
  title: '',
  data: [],
  getTagVariant: null,
};

function DashboardKPILists({
  className,
}) {
  const { t } = useTranslation();

  const [selectedEmail, setSelectedEmail] = useState(null);

  const {
    data: pendingLeases,
    isLoading: pendingLeasesIsLoading,
  } = useQuery({
    queryKey: ['pending-leases'],
    queryFn: getPendingLeases,
    keepPreviousData: false,
  });

  const pendingLeasesData = useMemo(() => pendingLeases?.data?.collection?.map((item) => ({
    uid: item.leaseUid,
    name: item.name,
    isNextSignerLessor: item.isNextSignerLessor,
    redirectUrl: `/lease/${item.leaseUid}`,
  })) || [], [pendingLeases]);

  const getPendingLeaseTagVariant = useCallback((item) => {
    if (item.isNextSignerLessor) {
      return {
        variant: 'error',
        label: t('dashboard.kpi.lists.leasesToSign.status.nextSignerLessor'),
        icon: 'alert-circle',
      };
    }
    return {
      variant: 'secondary',
      label: t('dashboard.kpi.lists.leasesToSign.status.nextSignerTenant'),
      icon: 'timer',
    };
  }, [t]);

  const {
    data: expiringLeases,
    isLoading: expiringLeasesIsLoading,
  } = useQuery({
    queryKey: ['expiring-leases'],
    queryFn: getExpiringLeases,
    keepPreviousData: false,
  });

  const expiringLeasesData = useMemo(() => expiringLeases?.data?.collection?.map((item) => ({
    uid: item.uid,
    name: item.name,
    terminationReason: item.terminationReason,
    redirectUrl: `/lease/${item.uid}`,
  })) || [], [expiringLeases]);

  const getExpiringLeaseTagVariant = useCallback((item) => {
    if (item.terminationReason === 'requested_by_tenant') {
      return {
        variant: 'secondary',
        label: t('dashboard.kpi.lists.leasesExpiring.status.requested_by_tenant'),
        icon: 'user',
      };
    }
    if (item.terminationReason === 'requested_by_lessor') {
      return {
        variant: 'tertiary',
        label: t('dashboard.kpi.lists.leasesExpiring.status.requested_by_lessor'),
        icon: 'key',
      };
    }
    return null;
  }, [t]);

  const {
    data: emailsSent,
    isLoading: emailsSentIsLoading,
  } = useQuery({
    queryKey: ['emails-sent'],
    queryFn: getEmailsSent,
    keepPreviousData: false,
  });

  const handleOpenEmail = useCallback((item) => {
    setSelectedEmail(item);
    showModal(EMAIL_MODAL_ID);
  }, []);

  const emailsSentData = useMemo(() => emailsSent?.data?.collection?.map((item) => ({
    uid: item.brevoUid,
    name: (
      <>
        {item.subject}
        <br />
        <small>{item.recipient}</small>
      </>
    ),
    status: item.status,
    onClick: () => handleOpenEmail(item),
  })) || [], [emailsSent, handleOpenEmail]);

  const getEmailSentTagVariant = useCallback((item) => {
    switch (item.status) {
      case 'sent':
        return {
          variant: 'secondary-outline',
          label: t('dashboard.kpi.lists.emailsSent.status.sent'),
          icon: 'send',
        };
      case 'delivered':
        return {
          variant: 'secondary',
          label: t('dashboard.kpi.lists.emailsSent.status.delivered'),
          icon: 'mail',
        };
      case 'open':
        return {
          variant: 'success',
          label: t('dashboard.kpi.lists.emailsSent.status.open'),
          icon: 'eye',
        };
      case 'click':
        return {
          variant: 'tertiary',
          label: t('dashboard.kpi.lists.emailsSent.status.click'),
          icon: 'tick-circle',
        };
      case 'hard_bounce':
        return {
          variant: 'error',
          label: t('dashboard.kpi.lists.emailsSent.status.hard_bounce'),
          icon: 'alert-triangle',
        };
      default:
        return {
          variant: 'primary',
          label: t('gloabl.form.unknown'),
          icon: 'alert-circle',
        };
    }
  }, [t]);

  const handleCloseEmailModal = useCallback(() => {
    hideModal(EMAIL_MODAL_ID);
    setSelectedEmail(null);
  }, []);

  return (
    <>
      <List
        id="pendingLeases"
        className={className}
        title={t('dashboard.kpi.lists.leasesToSign.title')}
        data={pendingLeasesData}
        isLoading={pendingLeasesIsLoading}
        getTagVariant={getPendingLeaseTagVariant}
      />
      <List
        id="expiringLeases"
        className={className}
        title={t('dashboard.kpi.lists.leasesExpiring.title')}
        data={expiringLeasesData}
        isLoading={expiringLeasesIsLoading}
        getTagVariant={getExpiringLeaseTagVariant}
      />
      <List
        className={className}
        title={t('dashboard.kpi.lists.emailsSent.title')}
        data={emailsSentData}
        isLoading={emailsSentIsLoading}
        getTagVariant={getEmailSentTagVariant}
      />
      <EmailModal
        idModal={EMAIL_MODAL_ID}
        html={selectedEmail?.html}
        status={selectedEmail?.status}
        onClose={handleCloseEmailModal}
      />
    </>
  );
}

DashboardKPILists.propTypes = {
  className: PropTypes.string,
};

DashboardKPILists.defaultProps = {
  className: '',
};

export default DashboardKPILists;
