import * as yup from 'yup';
import { validatePhoneNumber } from '../validation';

const twentyYearsBackDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 20);
  return date;
};

export const tenantInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  mobilePhoneCountryCode: null,
  mobilePhoneNumber: '',
  phoneCountryCode: null,
  phoneNumber: null,
  birthDate: twentyYearsBackDate(),
  taxCountry: 'FR',
  tenantNumber: null,
};

const tenantSchema = yup.object().shape({
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  lastName: yup.string().required('global.form.errors.required'),
  firstName: yup.string().required('global.form.errors.required'),
  mobilePhoneCountryCode: yup.string().required('global.form.errors.required').nullable(),
  mobilePhoneNumber: validatePhoneNumber('mobilePhoneNumber', 'mobilePhoneCountryCode')
    .required('global.form.errors.required'),
  phoneCountryCode: yup.string().nullable(),
  phoneNumber: validatePhoneNumber('phoneNumber', 'phoneCountryCode'),
  birthDate: yup
    .date()
    .max(
      new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
      'profile.personalInfo.errors.underaged',
    )
    .required('global.form.errors.required')
    .nullable(),
  taxCountry: yup.string().required('global.form.errors.required').nullable(),
  tenantNumber: yup.string().nullable(),
});

export default tenantSchema;
