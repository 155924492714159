/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Button,
} from 'ui-library-unlocker';

// Styles
import styles from './Toolbox.module.scss';

function ToolboxView() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('toolbox.browserTitle');
  }, [t]);

  const data = useMemo(() => [
    {
      label: t('toolbox.table.rows.emptyLease'),
      link: 'https://unlocker.io/wp-content/uploads/2024/10/modele_bail_logement_vide.docx',
    },
    {
      label: t('toolbox.table.rows.furnishedLease'),
      link: 'https://unlocker.io/wp-content/uploads/2024/10/modele_bail_logement_meuble.docx',
    },
    {
      label: t('toolbox.table.rows.commercialLease'),
      link: 'https://unlocker.io/wp-content/uploads/2024/10/modele_bail_commercial.docx',
    },
    {
      label: t('toolbox.table.rows.managementMandate'),
      link: 'https://unlocker.io/wp-content/uploads/2024/10/Mandat-de-Gestion-Locative-Unlocker.docx',
    },
    {
      label: t('toolbox.table.rows.inventoryFurnished'),
      link: 'https://unlocker.io/wp-content/uploads/2024/08/etat_des_lieux_meuble.pdf',
    },
    {
      label: t('toolbox.table.rows.inventoryEmpty'),
      link: 'https://unlocker.io/wp-content/uploads/2024/08/etat_des_lieux_non_meuble.pdf',
    },
  ], [t]);

  const columns = useMemo(() => ([
    {
      header: t('toolbox.table.columns.label'),
      accessorKey: 'label',
      size: 150,
      enableSorting: false,
    },
    {
      header: t('toolbox.table.columns.link'),
      accessorKey: 'link',
      size: 500,
      enableSorting: false,
      cell: ({ row: { original: { link } } }) => (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            label={t('global.listOptions.download')}
          />
        </a>
      ),
    },
  ]), [t]);

  return (
    <div>
      <div className={styles.heading}>
        <h1>{t('toolbox.title')}</h1>
      </div>
      <Table
        fullWidth
        columns={columns}
        data={data}
      />
    </div>
  );
}

ToolboxView.propTypes = {};

export default ToolboxView;
