/* eslint-disable func-names */
import * as yup from 'yup';

export const exportInitialValues = {
  startDate: new Date(new Date().getFullYear(), 0, 1),
  endDate: new Date(new Date().getFullYear() + 1, 0, 1),
};

export const exportSchema = yup.object().shape({
  startDate: yup.date().required('global.form.errors.required'),
  endDate: yup.date().required('global.form.errors.required'),
});
