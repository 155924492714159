import React, {
  useMemo, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowStrict } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { utils } from 'ui-library-unlocker';

// components
import HoverTag from '../../atoms/HoverTag/HoverTag';

// utils
import { handleDocumentDownload } from '../../../utils/documents';

// services
import { toggleHitNotification } from '../../../services/notifications';

import styles from './Notification.module.scss';

function Notification({
  item,
  onMarkAsRead,
  refetch,
}) {
  const {
    id,
    subject,
    content,
    hit,
    createdAt,
    category,
    entity,
    entityUid,
  } = item || {};

  const markAsReadRef = useRef(null);
  const { t } = useTranslation();

  const hitMutation = useMutation({
    mutationFn: () => toggleHitNotification(id),
    onSuccess: refetch,
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const timeAgo = useMemo(() => formatDistanceToNowStrict(new Date(createdAt), {
    locale: fr,
    addSuffix: true,
  }), [createdAt]);

  const toggleRead = useCallback((e) => {
    e?.stopPropagation();
    onMarkAsRead(id, !hit);
    hitMutation.mutate();
  }, [id, hit, hitMutation]);

  const handleClick = useCallback(() => {
    switch (entity) {
      case 'Document':
        handleDocumentDownload({ uid: entityUid }, t);
        break;
      default:
        break;
    }
    if (!hit) toggleRead();
  }, [entity, entityUid, toggleRead, t, hit]);

  const notifContent = useMemo(() => {
    switch (category) {
      case 'export':
        return t(`notifications.${category}`);
      default:
        return content;
    }
  }, [category, content]);

  return (
    <div
      className={styles.tooltip__option}
      role="button"
      tabIndex={0}
      onKeyDown={handleClick}
      onClick={handleClick}
    >
      <div className={styles.tooltip__option__content}>
        <div className={styles.tooltip__option__title}>
          <span>{subject}</span>
            &nbsp;
          <small className={styles.notifAgo}>
            {timeAgo}
          </small>
        </div>
        <span className={styles.tooltip__option__text}>
          {notifContent}
        </span>
      </div>
      <div
        className={styles.tooltip__option__markAsRead}
        ref={markAsReadRef}
      >
        <div
          className={utils.cn([
            styles.tooltip__option__markAsRead__icon,
            !hit && styles.notif_unread,
          ])}
          onClick={toggleRead}
          role="button"
          tabIndex={0}
          onKeyDown={toggleRead}
          aria-label={hit ? t('notifications.markAsUnread') : t('notifications.markAsRead')}
        />
        <HoverTag
          text={t(hit ? 'notifications.markAsUnread' : 'notifications.markAsRead')}
          position="left"
          parentRef={markAsReadRef}
        />
      </div>
    </div>
  );
}

Notification.propTypes = {
  item: PropTypes.shape({
    subject: PropTypes.string,
    content: PropTypes.string,
    hit: PropTypes.bool,
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    category: PropTypes.string,
    entity: PropTypes.string,
    entityUid: PropTypes.string,
  }).isRequired,
  onMarkAsRead: PropTypes.func,
  refetch: PropTypes.func,
};

Notification.defaultProps = {
  onMarkAsRead: () => {},
  refetch: () => {},
};

export default Notification;
