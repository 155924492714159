import api from './_client';

/**
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getKpi = () => api.get('/open-data/kpi');

export const getCurrentMonthFees = () => api.get('/payment/dashboard/fees');

export const getRentBalance = () => api.get('/payment/dashboard/year-balance');

export const getCurrentMonthUnlockerFees = () => api.get('/payment/dashboard/unlocker-fees');

export const getYearlyTrackedFees = () => api.get('/payment/dashboard/year-fees');

export const getPropertiesDistribution = () => api.get('/property/dashboard/properties-distribution');

export const getPendingLeases = () => api.get('/lease/dashboard/pending-leases');

export const getLeasesToReview = () => api.get('/lease/dashboard/review-leases');

export const getExpiringLeases = () => api.get('/lease/dashboard/expiring-leases');

export const getTenantsCount = () => api.get('/person/dashboard/tenants-count');

export const getUnreadMessagesCount = () => api.get('/messaging/dashboard/unread-messages');

export const getLifetimeFees = () => api.get('/payment/dashboard/fees');

export const getRentsWaitingForPayment = () => api.get('/payment/dashboard/month-payments');

export const getEmailsSent = () => api.get('/notification/dashboard/emails');
