import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { Toast } from 'ui-library-unlocker';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import ContextProvider from './store/context';
import Routes from './routes/Routes';
import reportWebVitals from './reportWebVitals';
import EnvBanner from './components/atoms/EnvBanner/EnvBanner';

import 'ui-library-unlocker/lib/index.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import i18n from './i18n';
// import { getIpAddress } from './utils/properties';

import './styles/index.scss';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.feedbackIntegration({
        isEmailRequired: true,
        isNameRequired: true,
        colorScheme: 'light',
        formTitle: 'Rapport de bug',
        buttonLabel: 'Signaler un bug',
        namePlaceholder: 'Jean Dupont',
        submitButtonLabel: 'Envoyer',
        cancelButtonLabel: 'Annuler',
        nameLabel: 'Nom',
        showBranding: false,
        messagePlaceholder: 'Quel est le bug ? Quel est le comportement que vous attendiez ?',
        successMessageText: 'Merci pour le signalement !',
      }),
      new Sentry.BrowserTracing(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0,
  });
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

(async () => {
  // const response = await getIpAddress();
  const tempIP = '0.0.0.0';
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
    context: {
      kind: 'user',
      key: `user-key-ip-${tempIP}`,
      ip: tempIP,
    },
  });

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <LDProvider>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <ContextProvider>
            <EnvBanner />
            <Routes />
            <Toast />
          </ContextProvider>
        </I18nextProvider>
        {/* Enable React Query Devtools if you want to debug queries */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </LDProvider>,
  );
})();

reportWebVitals();
