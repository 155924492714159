/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Components
import {
  utils,
  ProgressBar,
  Picto,
} from 'ui-library-unlocker';

// Services
import {
  getPendingLeases,
  getLeasesToReview,
  getExpiringLeases,
  getTenantsCount,
  getUnreadMessagesCount,
  getLifetimeFees,
  getRentsWaitingForPayment,
} from '../../../../services/stats';

// Hooks
import useRoles from '../../../../hooks/useRoles';
import useScroll from '../../../../hooks/useScroll';

// Styles
import styles from './SimpleStats.module.scss';

function SimpleNumber({
  text,
  value,
  redirectTo,
  shorterText,
  isLoading,
}) {
  const navigate = useNavigate();
  const { scrollToElement } = useScroll();

  const handleClick = useCallback(() => {
    if (redirectTo) {
      if (redirectTo.startsWith('#')) {
        scrollToElement(redirectTo.slice(1));
      } else {
        navigate(redirectTo);
      }
    }
  }, [redirectTo, navigate]);

  if (isLoading) {
    return (
      <div className={styles.simpleNumber}>
        <Skeleton className={utils.cn([styles.value, styles.skeletonValue])} width="50%" />
        <Skeleton className={styles.text} />
      </div>
    );
  }

  return (
    <div
      className={utils.cn([
        styles.simpleNumber,
        redirectTo ? styles.clickable : null,
        shorterText ? styles.shorterText : null,
      ])}
      onClick={handleClick}
      onKeyDown={handleClick}
      role={redirectTo ? 'button' : null}
    >
      <p className={styles.value}>{value}</p>
      <p className={styles.text}>
        <span>{text}</span>
        {redirectTo && (
          <span className={styles.arrowContainer}>
            <Picto
              icon="arrow-right"
              width={16}
              className={styles.arrow}
            />
          </span>
        )}
      </p>
    </div>
  );
}

SimpleNumber.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  redirectTo: PropTypes.string,
  shorterText: PropTypes.bool,
  isLoading: PropTypes.bool,
};

SimpleNumber.defaultProps = {
  redirectTo: '',
  shorterText: false,
  isLoading: false,
  value: 0,
};

function ProgressStat({
  text,
  value,
  total,
  redirectTo,
  isLoading,
  formatValue,
}) {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [redirectTo, navigate]);

  if (isLoading) {
    return (
      <div className={utils.cn([
        styles.simpleNumber,
        styles.progressStat,
        styles.shorterText,
      ])}
      >
        <Skeleton className={styles.value} width="40%" />
        <Skeleton height={9} />
        <Skeleton height={16} width="60%" />
      </div>
    );
  }

  return (
    <div
      className={utils.cn([
        styles.simpleNumber,
        styles.progressStat,
        redirectTo ? styles.clickable : null,
        styles.shorterText,
      ])}
      onClick={handleClick}
      onKeyDown={handleClick}
      role={redirectTo ? 'button' : null}
    >
      <p className={utils.cn([styles.value, 'm-l-10 m-b-5'])}>{formatValue(value)}</p>
      <ProgressBar
        value={value}
        total={total}
        size="fullWidth"
        showLabel={false}
      />
      <p className={utils.cn([styles.text, 'm-l-20'])}>{text}</p>
    </div>
  );
}

ProgressStat.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number,
  redirectTo: PropTypes.string,
  isLoading: PropTypes.bool,
  formatValue: PropTypes.func,
  total: PropTypes.number,
};

ProgressStat.defaultProps = {
  redirectTo: '',
  isLoading: false,
  formatValue: (value) => value,
  total: 100,
  value: 0,
};

function SimpleStats({
  className,
}) {
  const { t } = useTranslation();
  const { isUserRealEstateManager } = useRoles();

  const {
    data: pendingLeases,
    isLoading: pendingLeasesIsLoading,
  } = useQuery({
    queryKey: ['pending-leases'],
    queryFn: getPendingLeases,
    keepPreviousData: false,
  });
  const pendingLeasesData = useMemo(() => pendingLeases?.data || {}, [pendingLeases]);

  const {
    data: leasesToReview,
    isLoading: leasesToReviewIsLoading,
  } = useQuery({
    queryKey: ['leases-to-review'],
    queryFn: getLeasesToReview,
    keepPreviousData: false,
  });

  const {
    data: expiringLeases,
    isLoading: expiringLeasesIsLoading,
  } = useQuery({
    queryKey: ['expiring-leases'],
    queryFn: getExpiringLeases,
    keepPreviousData: false,
  });
  const expiringLeasesData = useMemo(() => expiringLeases?.data || {}, [expiringLeases]);

  const {
    data: tenantsCount,
    isLoading: tenantsCountIsLoading,
  } = useQuery({
    queryKey: ['tenants-count'],
    queryFn: getTenantsCount,
    keepPreviousData: false,
  });

  const {
    data: unreadMessagesCount,
    isLoading: unreadMessagesCountIsLoading,
  } = useQuery({
    queryKey: ['unread-messages-count'],
    queryFn: getUnreadMessagesCount,
    keepPreviousData: false,
  });

  const {
    data: lifetimeFees,
    isLoading: lifetimeFeesIsLoading,
  } = useQuery({
    queryKey: ['month-lifetime-fees'],
    queryFn: getLifetimeFees,
    keepPreviousData: false,
    enabled: isUserRealEstateManager,
  });
  const lifetimeFeesValue = useMemo(() => lifetimeFees?.data?.lifeTimeActualFees || 0, [lifetimeFees]);

  const {
    data: rentsWaitingForPayment,
    isLoading: rentsWaitingForPaymentIsLoading,
  } = useQuery({
    queryKey: ['rents-waiting-for-payment'],
    queryFn: getRentsWaitingForPayment,
    keepPreviousData: false,
  });

  const formatMoney = useCallback((value) => (
    `${utils.centsToEuro(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
  ), []);

  const formattedLifetimeFees = useMemo(() => formatMoney(lifetimeFeesValue), [formatMoney, lifetimeFeesValue]);

  return (
    <section className={utils.cn([
      styles.container,
      className,
    ])}
    >
      <SimpleNumber
        text={t('dashboard.kpi.simpleStats.leasesToSign')}
        value={pendingLeasesData.count}
        redirectTo="#pendingLeases"
        isLoading={pendingLeasesIsLoading}
      />
      <SimpleNumber
        text={t('dashboard.kpi.simpleStats.leasesToReview')}
        value={leasesToReview?.data?.count}
        isLoading={leasesToReviewIsLoading}
      />
      <SimpleNumber
        text={t('dashboard.kpi.simpleStats.leasesExpiring')}
        value={expiringLeasesData.count}
        redirectTo="#expiringLeases"
        isLoading={expiringLeasesIsLoading}
      />
      <SimpleNumber
        text={t('dashboard.kpi.simpleStats.tenants')}
        value={tenantsCount?.data?.count}
        redirectTo="/tenants"
        isLoading={tenantsCountIsLoading}
      />
      <SimpleNumber
        text={t('dashboard.kpi.simpleStats.unreadMessages')}
        value={unreadMessagesCount?.data?.count}
        redirectTo="/messages"
        isLoading={unreadMessagesCountIsLoading}
      />
      {isUserRealEstateManager && (
        <SimpleNumber
          text={t('dashboard.kpi.simpleStats.fees')}
          value={formattedLifetimeFees}
          shorterText
          isLoading={lifetimeFeesIsLoading}
        />
      )}
      <ProgressStat
        text={t('dashboard.kpi.simpleStats.rentsWaitingForPayment')}
        value={rentsWaitingForPayment?.data?.actual}
        total={rentsWaitingForPayment?.data?.expected}
        formatValue={formatMoney}
        isLoading={rentsWaitingForPaymentIsLoading}
      />
    </section>
  );
}

SimpleStats.propTypes = {
  className: PropTypes.string,
};

SimpleStats.defaultProps = {
  className: '',
};

export default SimpleStats;
