import api from './_client';
import { getDateFilters } from '../utils/dates';

export const getLessorPayments = ({ page, itemsPerPage, filters = {} }) => {
  const allStatusFilters = filters?.status?.flatMap((s) => s.split(','));
  const statusFilters = allStatusFilters?.filter((s) => [
    'scheduled',
    'scheduled_manual',
    'notified',
    'pending',
    'canceled',
    'success',
    'failed',
  ].includes(s));
  const accountingsStatusFilters = allStatusFilters?.filter((s) => [
    'received',
    'sent',
    'ready_to_wire',
    'ready_to_check',
    'in_wiring',
    'failed',
    'not_doable',
  ].includes(s));
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--in--]': statusFilters?.length > 0
      ? statusFilters.join?.(',')
      : undefined,
    'filters[accountingsStatus][--in--]': accountingsStatusFilters?.length > 0
      ? accountingsStatusFilters.join?.(',')
      : undefined,
    ...getDateFilters('cashInDate', filters, '[accountings][createdAt]'),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/payment/payments/lessor', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getAdminPayment = (uid) => api.post(`/payment/admin/payment/${uid}`);

export const getPayment = (uid) => api.get(`/payment/payments/${uid}`);

export const getPaymentReview = (uid) => api.post(`/payment/admin/payout/${uid}/review`);

export const getUserPayments = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[id][--in--]': filters.id?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/payment/payments', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const validatePayment = (uid) => api.post(`/payment/admin/payout/${uid}/validate`);

export const addManualPayment = (data) => api.post('/payment/manual/add', data);

export const retryPayment = (uid, data) => api.post(`/payment/admin/payment/${uid}/force-retry`, data);
