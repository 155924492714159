import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-unused-vars
import { utils } from 'ui-library-unlocker';
import { getDocumentUploadUrl, uploadToBucket, getDocumentPostAdUploadUrl } from '../services/document';

/**
 *
 * @param {function} createDocumentCb // callback function when file is upload on S3, always return a promise
 * @returns
 */
const useFileUpload = (createDocumentCb, postAd) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const getUploadUrlMutation = useMutation({
    mutationFn: (data) => (postAd ? getDocumentPostAdUploadUrl : getDocumentUploadUrl)({
      ...data,
      metadata: {
        ...data?.metadata,
        documentSize: Math.round(data.metadata.documentSize / 1024),
      },
    }),
    onSuccess: async ({ data, status }, variables) => {
      const { uid: documentUid } = data;
      switch (status) {
        case 200: {
          try {
            await uploadToBucket(data.presignedUrl, file);
            await createDocumentCb(documentUid, variables?.filename);

            utils.toast.success(t('global.form.success.fileUploaded'));

            setIsUploaded(true);
            setIsUploading(false);
          } catch (err) {
            switch (err?.response?.status) {
              case 424: {
                utils.toast.error(t(`global.form.errors.file.${err?.response?.data?.message}`));
                break;
              }
              default:
                utils.toast.error(t('global.form.errors.file.generic'));
                break;
            }
            setIsUploading(false);
          }
          break;
        }
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t(`global.form.errors.file.${err?.response?.data?.message}`));
            setError(err.response.data);
            setIsUploading(false);
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const uploadFile = useCallback((data) => {
    if (file) {
      setIsUploading(true);
      setError(null);
      getUploadUrlMutation.mutate(data);
    }
  }, [file]);

  /* Standalone function to upload multiple files
     fileList:
     {
        file: File,
        data: {
          filename: string,
          type: string,
          userUid: string,
          metadata: {
            documentSize: number,
            extension: string,
            // only for images
            height: number,
            width: number,
          }
        }
     }
  */
  const uploadFiles = useCallback(async (fileList, onFileReady = null) => {
    const getPresignedUrl = async (data) => {
      try {
        const url = await getDocumentUploadUrl({
          ...data,
          metadata: {
            ...data.metadata,
            documentSize: Math.round(data.metadata.documentSize / 1024),
          },
        });
        return url.data;
      } catch (e) {
        utils.toast.error(t('global.form.errors.file.generic'));
        throw new Error(e);
      }
    };

    if (!fileList || !fileList.length) return [];

    const response = await Promise.all(fileList.map(async (f, fIndex) => {
      const url = await getPresignedUrl(f.data);
      if (url) {
        try {
          await uploadToBucket(url.presignedUrl, f.file);
          if (onFileReady) onFileReady(fIndex, url, f);
          return url.uid;
        } catch (e) {
          utils.toast.error(t('global.form.errors.file.generic'));
          return null;
        }
      }
      return null;
    }));
    return response;
  }, []);

  return [{
    uploadFile,
    setFileType,
    setFile,
    uploadFiles,
  }, {
    isUploading, isUploaded, error, fileType, file,
  }];
};

export default useFileUpload;
