import MicroModal from 'micromodal';

export const showModal = (id, params) => MicroModal.show(id, {
  ...params,
  openClass: 'modalIsOpen',
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
});

export const hideModal = (id) => MicroModal.close(id);
