import axios from 'axios';
import api from './_client';

export const getMe = () => api.get('/identity-access/user/me');

export const validateOnboardingBasicsStatus = () => api.post('/identity-access/user/set_onboarding_basics_status', {
  hasCompletedOnboardingBasics: true,
});

export const registerUser = (data) => api.post('/identity-access/security/signup', data);

export const loginUser = (data) => api.post('/identity-access/security/login', data);

export const refreshUserToken = ({ refreshToken, username }) => api.post('/identity-access/security/refresh-token', {
  refreshToken,
  username,
});

export const forgotUserPassword = (data) => api.post('/identity-access/forgot-password', data);

export const forgotUserPasswordConfirm = (data) => api.post('/identity-access/forgot-password/confirm', data);

export const confirmSignUp = (data) => api.post('/identity-access/security/signup/confirm', data);

export const resendConfirm = (data) => api.post('/identity-access/security/signup/resend', data);

export const changeUserEmail = (data) => api.post('/identity-access/security/change-email', data);

export const changeUserPassword = (data) => api.post('/identity-access/security/change-password', data);

export const checkExistingEmail = async (data) => {
  try {
    await api.post('/identity-access/user/check-email', data);
    return false;
  } catch (error) {
    if (error.response?.status === 409) return true;
    return false;
  }
};

/*
{
  "token": "string",
  "password": "string"
}
*/
export const acceptInvitation = (data) => api.post('/identity-access/invitation/accept', data);

/*
{
  guestEmail: 'string',
  relation: 'tenant | propertyOwner',
}
*/
export const createInvitation = (data) => api.post('/identity-access/invitation', data);

export const impersonate = async (user) => {
  const uid = user?.uid;
  try {
    const { data } = await api.get(`/identity-access/admin/impersonation/${uid}/get-magic-link`);
    const splittedUrl = data?.link?.split('/') || [];
    const { data: impersonateData, ...rest } = await api.post('/identity-access/admin/impersonation/impersonate', {
      token: splittedUrl[splittedUrl.length - 1],
    });
    localStorage.setItem('accessTokenBackup', localStorage.getItem('accessToken'));
    localStorage.setItem('refreshTokenBackup', localStorage.getItem('refreshToken'));
    localStorage.setItem('accessToken', impersonateData?.accessToken?.token);
    localStorage.setItem('refreshToken', impersonateData?.accessToken?.refreshToken);
    localStorage.setItem('impersonateIdentity', JSON.stringify(user));
    localStorage.setItem('hasCompletedOnboardingFunnelBackup', localStorage.getItem('hasCompletedOnboardingFunnel'));
    localStorage.removeItem('hasCompletedOnboardingFunnel');
    localStorage.setItem('hasRealEstateAgencyBackup', localStorage.getItem('hasRealEstateAgency'));
    localStorage.removeItem('roleSpace');
    // eslint-disable-next-line no-restricted-globals
    location.replace('/');
    return { data: impersonateData, ...rest };
  } catch {
    throw new Error('Erreur dans la fonction impersonate');
  }
};

export const registerUserBeta = (data) => axios
  .create({
    baseURL: `https://${process.env.REACT_APP_API_URL}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    timeout: 30000,
  })
  .post('/notification/webhook', data);

export const becomeLessor = () => api.post('/identity-access/user/become-lessor');

export const quickSignUp = (data) => api.post('/person/listing/anonymous/get-uid', data);

export const checkEmailIsNotAccount = (data) => api.post('/person/listing/anonymous/available', data);
