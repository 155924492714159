import * as yup from 'yup';
import addressSchema from './addressSchema';
import { PROPERTY_STATUS } from '../constants';

export const propertyStatus = Object.values(PROPERTY_STATUS);
export const propertyTypes = ['apartment', 'house', 'garage', 'parking', 'box', 'office', 'festival_hall', 'other'];

export const propertyInitialValues = {
  status: '',
  name: '',
  type: '',
  furnished: null,
  address: null,
  constructionYear: null, // int
  tantiemes: '', // string
  surface: null, // int
  numberOfRooms: null, // int
  numberOfBathrooms: null, // int
  numberOfBedrooms: null, // int
  description: '',
  rentExcludingCharges: null, // int
  rentSupplement: null, // int
  charges: null, // int
  chargesRepartition: null, // string
  heatingChargesIncluded: false, // bool
  coldWaterChargesIncluded: false, // bool
  hotWaterChargesIncluded: false, // bool
  otherChargesIncluded: '', // string
  deliveryPoint: '', // string
  taxNumber: null, // string
  cadastralRef: null, // string
  ownerUid: '', // string
  fees: null, // string
  managerUid: '', // string
  unlockerID: '', // string
};

const propertySchema = yup.object().shape({
  status: yup.string().required('global.form.errors.required'),
  name: yup.string().required('global.form.errors.required'),
  type: yup.string().required('global.form.errors.required'),
  furnished: yup.boolean().required('global.form.errors.required').nullable(),
  address: addressSchema.required('global.form.errors.required').nullable(),
  constructionYear: yup
    .number()
    .test(
      'constructionYear-regex',
      'property.crud.formErrors.constructionYear',
      (value) => {
        if (value === null) return true;
        return (/^\d{4}$/.test(value));
      },
    )
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .nullable(),
  rentSupplement: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .nullable(),
  // If filled, property is considered a co-ownership
  tantiemes: yup.string().test(
    'tantiemes-regex',
    'property.crud.formErrors.tantiemes',
    (value) => !value || value.match(/^\d+\/\d+$/),
  ),
  surface: yup
    .number()
    .integer('global.form.errors.integer')
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  numberOfRooms: yup
    .number()
    .integer('global.form.errors.integer')
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  numberOfBathrooms: yup
    .number()
    .integer('global.form.errors.integer')
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  numberOfBedrooms: yup
    .number()
    .integer('global.form.errors.integer')
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  description: yup.string().required('global.form.errors.required'),
  rentExcludingCharges: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  charges: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  chargesRepartition: yup
    .string()
    .nullable()
    .when('charges', {
      is: (charges) => charges && parseFloat(charges) > 0,
      then: yup.string().nullable().required('global.form.errors.required'),
    }),
  heatingChargesIncluded: yup.boolean().nullable(),
  coldWaterChargesIncluded: yup.boolean().nullable(),
  hotWaterChargesIncluded: yup.boolean().nullable(),
  otherChargesIncluded: yup.string(),
  deliveryPoint: yup
    .string()
    .test({
      name: 'deliveryPoint',
      message: 'property.crud.formErrors.deliveryPoint',
      test: function test(value) {
        const { type } = this.parent;
        const isWhitelisted = ['garage', 'box', 'parking'].includes(type);
        if (isWhitelisted) {
          return true;
        }
        return (/^\d{14}$/.test(value));
      },
    }),
  taxNumber: yup.string()
    .test({
      name: 'taxNumber',
      message: 'property.crud.formErrors.taxNumber',
      test: function test(value) {
        if (!value) return true;
        return (/^\d{10}$/.test(value));
      },
    })
    .nullable(),
  cadastralRef: yup.string().nullable(),
  // This field is required only if the user is a real estate manager
  ownerUid: yup.string().required('global.form.errors.required').nullable(),
  fees: yup.number().when('$needFees', {
    is: true,
    then: yup
      .number()
      .typeError('global.form.errors.number')
      .min(0, 'global.form.errors.percentage')
      .max(100, 'global.form.errors.percentage')
      .required('global.form.errors.required')
      .nullable(),
    otherwise: yup.number().nullable(),
  }),
  // This field is required only if the user is an owner manager
  managerUid: yup.string().required('global.form.errors.required'),
});

export const propertyCharacteristicsInitialValues = {
  equipments: null,
  heatings: null,
  gesScore: null,
  dpeScore: null,
  minConsumption: 0,
  maxConsumption: 0,
  referenceYear: null,
};

export const propertyCharacteristicsSchema = yup.object().shape({
  equipments: yup.array().of(yup.string()).required('global.form.errors.required').nullable(),
  heatings: yup.array().of(yup.string()).required('global.form.errors.required').nullable(),
  gesScore: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .nullable(),
  dpeScore: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .nullable(),
  minConsumption: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  maxConsumption: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  referenceYear: yup.number().nullable(),
});

export default propertySchema;
