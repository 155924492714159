/* eslint-disable max-len */
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation, useMatches } from 'react-router-dom';
import { Picto, Tag, utils } from 'ui-library-unlocker';

// Components
import TenantForm from '../../components/organisms/forms/TenantForm/TenantForm';
import TenantDocuments from './TenantDocuments/TenantDocuments';
import TenantSignedDocuments from './TenantSignedDocuments/TenantSignedDocuments';
import BankingIdentityView from '../BankingIdentity/BankingIdentityList';

// Services
import { getTenant } from '../../services/tenant';
import { getTenantFromAdmin } from '../../services/admin';

// Hooks
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';

import styles from './TenantEdit.module.scss';

function TenantEdit({ isEditing }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { isUserAdmin } = useRoles();
  const { context: { user } } = useAppContext();

  const match = useMatches()?.find((m) => m.pathname === location.pathname);

  useEffect(() => {
    switch (location.hash) {
      case '#documents':
        document.title = t('tenant.tabs.documents.browserTitle');
        break;
      case '#property-signed-documents':
        document.title = t('tenant.tabs.propertySignedDocuments.browserTitle');
        break;
      default:
        document.title = t('tenant.tabs.generalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const tenantQuery = useQuery({
    queryKey: ['tenant-id', isEditing, match.params?.id],
    queryFn: () => {
      if (isEditing && match?.params?.id !== 'add') {
        if (isUserAdmin) return getTenantFromAdmin(match?.params?.id);
        return getTenant(match?.params?.id);
      }
      return null;
    },
    cacheTime: 0,
  });

  const userHasDelegation = useMemo(() => {
    const tenantData = tenantQuery?.data?.data;
    return tenantData?.isDelegated && user?.username === tenantData?.delegatedTo;
  }, [tenantQuery, user]);

  const tabs = useMemo(() => [
    {
      label: t('tenant.tabs.generalInfo.tabTitle'),
      title: t('tenant.tabs.generalInfo.title'),
      hash: '',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <TenantForm isEditing={isEditing} tenantQuery={tenantQuery} />
      ),
      show: true,
    },
    {
      label: t('tenant.tabs.documents.tabTitle'),
      title: t('tenant.tabs.documents.title'),
      hash: 'documents',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <TenantDocuments />
      ),
      show: true,
    },
    {
      label: t('tenant.tabs.propertySignedDocuments.tabTitle'),
      title: t('tenant.tabs.propertySignedDocuments.title'),
      hash: 'property-signed-documents',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3513" />,
      component: (
        <TenantSignedDocuments />
      ),
      show: true,
    },
    {
      label: t('tenant.tabs.bankingIdentities.tabTitle'),
      title: t('tenant.tabs.bankingIdentities.title'),
      slug: `/tenant/${match.params?.id}/banking-identities`,
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3513" />,
      component: (
        <BankingIdentityView crudModal delegatedTenantUID={match.params?.id} />
      ),
      show: userHasDelegation,
    },
  ], [t, location, isEditing, tenantQuery, match, userHasDelegation]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    const tab = tabs.find((cur) => (
      (
        (cur.hash && cur.hash === hash)
        || location.pathname === cur.slug
      )
      && cur.show
    ));
    return tab || tabs[0];
  }, [tabs, location]);

  const {
    firstName, lastName, legalName, status,
  } = tenantQuery?.data?.data || {};

  const personStatusTagVariant = useMemo(() => {
    switch (status) {
      case 'enabled':
        return 'secondary-outline';
      case 'disabled':
        return 'error';
      case 'pending':
        return 'secondary';
      case 'isPendingInvitation':
        return 'tertiary';
      default:
        return 'error';
    }
  }, [status]);

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <div className={styles.titleBlock}>
            <h1>{isEditing ? t('tenant.crud.editTitle') : t('tenant.crud.addTitle')}</h1>
            {userHasDelegation && (
              <Tag
                label={t('tenant.table.columns.delegated')}
                size="medium"
                variant="tertiary"
              />
            )}
          </div>
          { isEditing && tenantQuery?.data && (
          <div className={utils.cn(['m-t-20', styles.editDetails])}>
            <h2 className="t-h2-500">{`${firstName} ${lastName} ${legalName ? `- ${legalName}` : ''}`}</h2>
            <Tag
              label={t(`global.personStatus.${status}`)}
              size="medium"
              variant={personStatusTagVariant}
            />
          </div>
          )}
        </div>
        {isEditing ? (
          <ul className={styles.tabList}>
            {tabs.map((tab) => {
              if (!tab.show) return null;
              const isActive = tab.hash === currentTab.hash || (tab.slug && tab.slug === currentTab.slug);

              return (
                <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash || tab.slug}>
                  <Link to={tab.slug || `/tenant/${match.params?.id}#${tab.hash}`}>{tab.label}</Link>
                  {isActive && tab.activePicto}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

TenantEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default TenantEdit;
