import * as yup from 'yup';

import { validatePhoneNumber } from '../validation';

export const registerInitialValues = {
  email: '',
  password: '',
  acceptedGTC: false,
  acceptedGDPR: false,
};

const registerSchema = yup.object().shape({
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  password: yup.string()
    .required('global.form.errors.required')
    .min(8, 'global.form.errors.password')
    .test('isValidPassword', 'global.form.errors.password', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);

      const conditions = [hasUpperCase, hasNumber, hasSpecialChar];

      return conditions.find((c) => c !== true) === undefined;
    }),
  acceptedGTC: yup.bool().required().oneOf([true], 'register.form.errors.acceptedGTC'),
  acceptedGDPR: yup.bool().required().oneOf([true], 'register.form.errors.acceptedGDPR'),
});

// BETA
export const betaRegisterInitialValues = {
  firstName: '',
  lastName: '',
  leadType: '',
  email: '',
  numberOfProperty: null, // int
  phoneNumber: '',
  phoneCountryCode: null,
};

export const betaRegisterSchema = yup.object().shape({
  firstName: yup.string().required('global.form.errors.required'),
  lastName: yup.string().required('global.form.errors.required'),
  leadType: yup.string()
    .required('global.form.errors.required')
    .oneOf(['owner', 'real_estate'], 'global.form.errors.required'),
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  numberOfProperty: yup.number()
    .nullable()
    .moreThan(-1, 'global.form.errors.negativeNumber'),
  phoneCountryCode: yup.string().required('global.form.errors.required').nullable(),
  phoneNumber: validatePhoneNumber('phoneNumber', 'phoneCountryCode')
    .required('global.form.errors.required'),
});

export default registerSchema;
