import { useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Picto,
  utils,
  TextInput,
  CheckboxInput,
  Button,
} from 'ui-library-unlocker';

// Components
import WithLogin from '../../components/templates/WithLogin/WithLogin';
import DiscoverLink from '../../components/atoms/DiscoverLink/DiscoverLink';

import { acceptInvitation } from '../../services/identity';

import { useAppContext } from '../../store/context';

// Utils
import { displayError, isFieldValid } from '../../utils/forms/form';

import styles from './SetPassword.module.scss';

const validationSchema = yup.object().shape({
  password: yup.string()
    .required('global.form.errors.required')
    .min(8, 'global.form.errors.password')
    .test('isValidPassword', 'global.form.errors.password', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);

      const conditions = [hasUpperCase, hasNumber, hasSpecialChar];

      return conditions.find((c) => c !== true) === undefined;
    }),
  acceptedGTC: yup.bool().required().oneOf([true], 'register.form.errors.acceptedGTC'),
  acceptedGDPR: yup.bool().required().oneOf([true], 'register.form.errors.acceptedGDPR'),
});

// eslint-disable-next-line react/prop-types
function ExternalLink({ href, children }) {
  return <a href={href} target="_blank" rel="noreferrer">{children}</a>;
}

function SetPassword() {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const { context: { accessToken } } = useAppContext();

  const code = search.get('code');

  useEffect(() => {
    document.title = t('setPassword.browserTitle');
  }, [t]);

  useEffect(() => {
    if (!code) navigate('/login');
  }, [code]);

  const formik = useFormik({
    initialValues: {
      password: '',
      acceptedGTC: false,
      acceptedGDPR: false,
    },
    validate: (values) => {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
      } catch (err) {
        const errorList = err.inner.reduce((errors, error) => {
          errors[error.path] = error.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => acceptInvitationMutation.mutate({
      ...values,
      token: code,
    }),
  });

  const acceptInvitationMutation = useMutation({
    mutationFn: acceptInvitation,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('setPassword.success'));
          window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate('/login');
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const renderContentHeading = useCallback(() => (
    <div>
      <h1 className={styles.title}>
        {t('setPassword.header')}
      </h1>
      <Picto
        width={154}
        color="var(--color-tertiary)"
        icon="trait-login-title"
      />
      <p className={styles.subTitle}>
        {t('setPassword.subHeader')}
      </p>
    </div>
  ), []);

  const renderContentLeft = useCallback(() => (
    <div className={styles.contentLeft}>
      <div className={styles.imageBox}>
        <img className={styles.image} src="/images/jess.jpg" alt="cover" />
        <Picto
          className={styles.gribouillon}
          width={93}
          fill="var(--color-tertiary)"
          color="transparent"
          icon="gribouillon-6"
        />
        <Picto
          className={styles.dot}
          width={46}
          color="var(--color-secondary)"
          icon="point-moyen-900"
        />
      </div>
    </div>
  ), []);

  if (accessToken) {
    navigate('/');
    return null;
  }

  return (
    <WithLogin
      contentLeft={renderContentLeft()}
      contentHeading={renderContentHeading()}
    >
      <>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            type="password"
            id="password"
            name="password"
            className="m-t-25"
            label={t('register.form.password')}
            error={displayError(t, formik, 'password')}
            valid={isFieldValid(formik, 'password')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            info={t('global.form.info.password')}
            autoComplete="new-password"
          />
          <CheckboxInput
            name="terms"
            id="terms"
            className="m-t-25 p-2-500"
            label={(
              <Trans
                i18nKey="register.form.acceptedGTC"
                t={t}
                components={[<ExternalLink href="https://unlocker.io/conditions-generales" />]}
              />
                  )}
            checked={formik.values.acceptedGTC}
            onChange={() => {
              formik.setFieldValue('acceptedGTC', !formik.values.acceptedGTC);
            }}
            error={displayError(t, formik, 'acceptedGTC')}
          />
          <CheckboxInput
            name="terms"
            id="terms"
            className="m-t-20 p-2-500"
            label={(
              <Trans
                i18nKey="register.form.acceptedGDPR"
                t={t}
                components={[<ExternalLink href="https://www.unlocker.io/politique-de-confidentialite" />]}
              />
                  )}
            checked={formik.values.acceptedGDPR}
            onChange={() => {
              formik.setFieldValue('acceptedGDPR', !formik.values.acceptedGDPR);
            }}
            error={displayError(t, formik, 'acceptedGDPR')}
          />
          <Button
            type="submit"
            className="m-t-30 center-block"
            size="large"
            label={t('setPassword.submit')}
          />
        </form>
        <p className={utils.cn(['p-2-500 text-center m-t-20', styles.haveAccount])}>
          {t('register.alreadyHaveAccount')}
          <Link className="m-l-10" to="/login">
            {t('register.signIn')}
          </Link>
        </p>
        <p className={utils.cn(['m-t-40 m-b-30 p-2-500', styles.legalContext])}>
          {t('register.form.legalContext')}
        </p>
        <div className="m-t-30 m-b-30 text-center">
          <DiscoverLink />
        </div>
      </>
    </WithLogin>
  );
}

export default SetPassword;
